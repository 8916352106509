import { useMemo } from "react";

import { moveToCommune } from "@/components/template/mypage/MyPageIndex/helpers";
import { useGetMypageLink } from "@/generated/open-api/customer/customer";
import { useParsedGetSubscription } from "@/queries";

import { ImageLink } from "../ImageLinkItem";

const images: ImageLink[] = [
  {
    url: "https://asset.basefood.co.jp/images/bnr/bnr_safety_W1080_H1080_2.jpg",
    alt: "BASE FOODの安全",
    href: "https://basefood.co.jp/safety",
  },
  {
    url: "https://asset.basefood.co.jp/images/bnr/bnr_campaign_shopping_new_items_W1080_H1080_0826.png",
    alt: "新商品のお知らせ",
    isInternalLink: true,
    href: "/campaign/shopping_new_items",
  },
  {
    url: "https://asset.basefood.co.jp/images/bnr/bnr_invite_W1080_H1080_0828.png",
    alt: "友だち紹介キャンペーン",
    isInternalLink: true,
    href: "/invite",
  },
  {
    url: "https://asset.basefood.co.jp/images/bnr/bnr_app_download_W1080_H1080_0612.png",
    alt: "アプリをダウンロード",
    href: "https://apps.apple.com/jp/app/id1671534626",
  },
  {
    url: "https://asset.basefood.co.jp/images/bnr/bnr_pointpack_W1080_H1080.png",
    alt: "ポイントパックのご案内",
    href: "/yearplan",
  },
  {
    url: "https://asset.basefood.co.jp/images/bnr/bnr_labo_W466_H466.png",
    alt: "BASE FOOD labo",
    onClick: () => moveToCommune(),
  },
  {
    url: "https://asset.basefood.co.jp/images/bnr/bnr_recruit_W466_H466.png",
    alt: "採用情報",
    href: "https://docs.google.com/forms/d/e/1FAIpQLSc_ILZhFdFTzxzOvgm_xZXhdhEJpmfT4GHjpB_UMX9_2gU_Hw/viewform",
  },
  {
    url: "https://asset.basefood.co.jp/images/bnr/bnr_brandbook_W1080_H1080.jpg",
    alt: "ブランドについて",
    href: "https://basefood.co.jp/brand",
  },
];

export const useImageLinks = () => {
  const { data: subscription } = useParsedGetSubscription();
  const { data: outletLink } = useGetMypageLink({
    query: { enabled: !!subscription.subscription || !!subscription.freezeSubscription },
  });

  const imageLinks = useMemo(() => {
    if (!outletLink?.is_display_outlet_link) return images;

    const outletImage = {
      url: "https://asset.basefood.co.jp/images/bnr/bnr_outlet_tarako_creamymushroom.png",
      alt: "アウトレット商品のご案内",
      href: outletLink.outlet_url,
    };

    return [outletImage, ...images];
  }, [outletLink]);

  return { imageLinks };
};
